<template>
  <div class="el_information">
    <div class="mbanner mbanner2"
         :style="{backgroundImage: `url(${require('../../assets/images/mbanner11.jpg')})`}">
      <div class="wrapper">
        <div class="mbanner2-tit">{{title}}</div>
        <div class="mbanner2-line"></div>
        <div class="mbanner2-en">{{en}}</div>
      </div>
    </div>

    <div class="tylb mrow ">
      <div class="wrapper">
        <div class="tylb-list">
          <ul>
            <li v-for="(item,index) in list"
                :key="index"
                @click="toRouter('article',item)">
              <a>
                <div class="img"
                     v-if="noImage!=1">
                  <img :src="item.photo"
                       v-if="name == '融媒体'" />
                  <img :src="item.cover"
                       v-else />
                </div>
                <div class="info" :class="{ imageCancle: noImage==1 }">
                  <h3 class="tit">{{item.title}}</h3>
                  <div class="time">{{item.releaseTime}}</div>
                  <div class="txt"
                       v-if="name == '融媒体'">
                    {{item.summary}}
                  </div>
                  <div class="txt"
                       v-else>
                    {{item.articleAbstract}}
                  </div>
                  <span class="mbtn small">查看详情</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="mpages">
          <!-- <a class="btn">上一页</a>
          <a class="active"
             href="#">1</a>
          <a class="btn">下一页</a> -->
          <el-pagination background
                         layout="prev, pager, next"
                         :total='total'
                         :currentPage='currentPage'
                         :pageSize='10'
                         @current-change='pageChange'>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      msg: "el_activitynews",
      title: '',// 中文
      en: '',// 英文
      type: '',
      list: [
        // {
        //   url: require('../../assets/images/l5.png'),
        //   title: '赋能供需 产业互联｜与鹿泉区电子信息企业面对面',
        //   time: '2021-12-24',
        //   content: '为深入贯彻落实市委、市政府关于新能源新材料产业发展的重大决策部署，加快建立以企业为主体、市场为导向、产学研深度融合的技术创新体系，探索科技成果转化的新机制和新模式，实现高水平科技成果与高质量科技企业的精准对接，由邢台市科学技术局主办，河北诺亚德汇人力资源服务有限公司（中国石家庄人力资源服务产业园）承办，邢台市开发区科学技术局、巨鹿县科技和工业信息化局、沙河市科技和工业信息化局、邢台高新区共同协办的“2021邢台市新能源新材料领域科技成果线上直通车活动”于10月19日成功举办。邢台市科学技术局副局长郝舒微参会并致辞。'
        // },
      ],
      currentPage: 1,
      total: 1,
      name: '',
    };
  },
  created () {
    console.log('this.$route.query',this.$route.query);
    this.title = this.$route.query.title || ''
    this.en = this.$route.query.en || ''
    this.type = this.$route.query.type || ''
    this.name = this.$route.query.name || '' // '融媒体'
    this.noImage = this.$route.query.noImage || '' // 政策不展示图片
    console.log(this.$route.query, 88776)
  },
  mounted () {
    if (this.name == '融媒体') {
      this.getMediaList();
    } else {
      this.getArticleList();
    }

    document.title = this.title
  },
  methods: {
    toRouter (routerName, item) {
      if (this.name == '融媒体') {
        console.log(item, 'ddddddddd')
        this.api.tongji({ user_id: 26, site: 53, Title: item.title, url: 'article' }).then((res) => {
          console.log(res, 998888)
          let query = {
            id: item.id,
            name: 2,
          }
          this.newRouter('/' + routerName, query)
        });
        return;
      }

      let query = {
        id: item.id
      }
      this.newRouter('/' + routerName, query)
    },
    getArticleList () {
      this.api.getArticleList({ pageNo: this.currentPage, pageSize: 10, type: this.type }).then(res => {
        this.list = res.data.result.records;
        this.currentPage = res.data.result.current;
        this.total = res.data.result.total;
      })
    },
    getMediaList () {
      this.api
        .getMediaList({ pageNo: 1, pageSize: 10, mediaType: this.type })
        .then((res) => {
          this.list = res.data.result.records;
          this.currentPage = res.data.result.current;
          this.total = res.data.result.total;
        });
    },
    pageChange (ev) {
      this.currentPage = ev;
      if (this.name == '融媒体') {
        this.getMediaList();
      } else {
        this.getArticleList();
      }
    }
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 1201px) {
   .imageCancle{
      width: 100%;
   }
}
</style>
